.regionFilters {
  .regionButton {
    background-color: #ffffff;
    color: #202020;
    border-color: #20202033;
    border-radius: 10px;
    padding: 8px 10px;
    text-align: center;
    font-size: 1rem;
    text-transform: initial;

    @media screen and (max-width: 1024px) {
      padding: 5px 8px;
      font-size: 1rem;
    }
  }
}

.noResults {
  font-size: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
