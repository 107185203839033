.offerCard {
  display: flex;
  background-color: #fff;
  padding: 0.5rem;
  //box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.02);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  transition: all 0.2s ease-in-out;

  &.similarOffers {
    // box-shadow: 
    //   0 5px 5px rgba(0, 0, 0, 0.1), 
    //   0 10px 10px rgba(0, 0, 0, 0.08), 
    //   0 15px 15px rgba(0, 0, 0, 0.06);

    // box-shadow: 
    // 4px 4px 0px #bbb, 
    // 8px 8px 0px #999, 
    // 12px 12px 0px #777;

    box-shadow: 
    4px 4px 0px #cacaca, 
    8px 8px 1px #b3b1b1
  }

  &:hover {
    box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.07);

    &.similarOffers {
      box-shadow: 
        6px 6px 1px #cacaca, 
        10px 10px 2px #b3b1b1;
    }
    transition: all 0.2s ease-in-out;
    transform: scale(1.03);
  }
}
.offerImage {
  max-width: 30%;
  width: 100px;
  height: 100px;
  position: relative;
  margin-top: -20px;
  margin-left: -20px;
}

.offerDetailsImageContainer {
  @media screen and (max-width: 1024px) {
    min-height: 150px;
  }
}

.offerData {
  width: 80%;
  padding-left: 0.5rem;
  .offerOverline {
    font-size: 11px;
    color: #9b9b9b;
    margin-bottom: 0;
    width: 100%;
    padding: 0;
    line-height: 1.5;

    @media screen and (max-width: 1024px) {
      b {
        font-size: 0.813rem;
      }
    }
  }

  .airlineLogo {
    max-height: 35px;
  }

  .offerPrice {
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    i {
      font-style: normal;
      color: #9b9b9b;
      font-size: 0.813rem;
    }
  }

  .regularPrice {
    color: #bb9269;
    font-size: 0.813rem;
    font-weight: 600;
  }

  .offerLink {
    text-decoration: none;
    text-transform: capitalize;
    color: #fff;
    background-color: #3c6562;
    font-size: 1rem;
    padding: 10px 20px;
    width: 225px;
    border-radius: 10px;
    display: block;
    text-align: center;

    &:hover {
      background-color: #00454A;
      color: #fff;
      transition: all 0.2s ease-in-out;
    }
  }

  h3 {
    text-align: left;
    font-weight: 400;
  }
}

.closeDialog {
  z-index: 1;
  border-radius: 50%;
  background-color: #d4a976 !important;
  color: white !important;
  margin-bottom: 0.5rem;
  width: 40px;
  height: 40px;

  &:hover {
    background-color: #86694b !important;
  }
}

.closeShareDialog {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 200;
  width: 50px;
  height: 50px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
}

.offerDetailsContainer {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.02);

  @media screen and (max-width: 1024px) {
    padding: 10px;
  }
}

.chartContainer {
  @media screen and (min-width: 1024px) {
    height: 300px;
  }
  height: 500px;
}

.hint {
  color: #9b9b9b;
  font-size: 12px;
  line-height: 15px;
  padding-left: 16px;
  margin-bottom: 1rem;
}

.shareIcon {
  position: absolute !important;
  bottom: 4px;
  right: 4px;
  z-index: 10;
  height: 40px;
  width: 40px;
  background-color: #d4a976 !important;
  color: #fff !important;

  @media screen and (max-width: 1024px) {
    top: 4px;
    bottom: auto;
  }
}

.offerTableItem {
  border-bottom: 1px solid #0a0a0a;
  padding: 3px 0;
  display: flex;
  align-items: center;
  color: #0a0a0a;
  text-decoration: none;
  transition: all 0.3 ease;

  &:hover {
      font-weight: bold;
      cursor: pointer;
      background-color: #fff;
      text-decoration: none;
      // transform: scale(1.05);
  }

  .offerName {
    font-size: 0.9rem;
    width: 38%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
  }
  .offerAirlineImage {
    width: 10%;
    text-align: center;
  
    img {
        width: 28px;
    }
    
  }
  .offerPrice {
    font-size: 0.9rem;
    width: 27%;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  
  
    .price {
        text-decoration: underline;
    }
  
    @media screen and (max-width: 800px) {
        .fromLabel {
            display: none;
        }
    }
  
    // @media screen and (max-width: 1024px) {
    //     text-decoration: underline;
    // }
  }
  
  .offerDate {
    font-size: 0.7rem;
    color: #202020;
    width: 23%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;

    .similarOffersCount {
      font-size: 0.7rem;
      font-weight: bold;
      background-color: #00454A;
      color: #fff;
      border-radius: 50%;
      text-align: center;
      padding: 2px;
      width: 20px;
      height: 20px;
      display: flex;
      margin-left: 4px;
      align-items: center;
      justify-content: center;
    }
  }
}

.chooseDatesHint {
  background-color: #F3EDDC;
  border: 2px solid #D4A976;
  border-radius: 5px;
  font-size: 1rem;
  padding: 10px;
}

.noResults {
  font-size: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
